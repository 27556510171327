import React, { useEffect, useState } from "react";

function PlanningPokerPointsDays({type, number, setSelfDays, setSelfStoryPoint}) {
      
    function changeStoryOrDays(){
        type === 'storypoints' ? setSelfStoryPoint(number) : setSelfDays(number);
    }

    return (
        <>
            <div className="story-days-boxed" key={type+number} style={{borderColor:'blue', display:'flex', flexDirection:'column', justifyContent:'space-around'}} onClick={changeStoryOrDays}>  
                {number}
            </div>
        </>
        
      );
}

export default PlanningPokerPointsDays;


