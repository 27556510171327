import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import './css/Codename.css';
import './css/PlanningPoker.css';
import io from "socket.io-client";
import JoinRoom from "./components/JoinRoom";
import PlanningPokerPointsDays from "./components/planningpoker/PlanningPokerPointsDays";
import PlanningPokerPlayersList from "./components/planningpoker/PlanningPokerPlayersList";
import { useIsMount } from './components/useIsMount';
// const socket = io.connect("http://localhost:3001");
const socket = io.connect("https://robertcalamari-server.onrender.com");

function PlanningPoker(props) {

    const [hideSignIn, setHideSignIn] = useState("block");
    const [showGameRoom, setShowGameRoom] = useState("none");
    const [showWaitingRoom, setShowWaitingRoom] = useState("none");
    const [playersList, setPlayersList] = useState([{id:0, name:'nameid', loggedin:true, roomname:'rname', host:false, gameinfo: {}}]);
    const [roomName, setRoomName] = useState('');
    const [roomType, setRoomType] = useState('planningpoker');
    const [info, setInfo] = useState('codename');
    const [desc, setDesc] = useState(['Waiting on the host* to start the round.'],[]);
    const [activePlayerClient, setActivePlayerClient] = useState('');
    const [newSocket, setNewSocket] = useState("");
    const isMount = useIsMount(); 
    const [selfStoryPoint, setSelfStoryPoint] = useState('-');
    const [selfDays, setSelfDays] = useState('-');
    const [isStarted, setIsStarted] = useState('false');

    
    useEffect(() => {
          document.title = props.title; // eslint-disable-next-line
    }, []); 

    function exitGame(){
        socket.emit("exit_codename_game", { where:'exitedgame', room:roomName});
    }

    function startRound(){
        socket.emit("planningpoker_start_round", { playername:activePlayerClient, number:selfStoryPoint, room:roomName, desc:info});
    }

    function revealRound(){
        socket.emit("planningpoker_reveal_round", { playername:activePlayerClient, number:selfStoryPoint, room:roomName, desc:desc});
    }

    useEffect(() => {
        if (isMount) {
        } else {
            socket.emit("planningpoker_update_storypoints", { playername:activePlayerClient, number:selfStoryPoint, room:roomName});
        }
        
    }, [selfStoryPoint]); 

    useEffect(() => {
        if (isMount) {
        } else {
          socket.emit("planningpoker_update_days", { playername:activePlayerClient, number:selfDays, room:roomName});
        }
        
    }, [selfDays]); 

    useEffect(() => {
        socket.on('updateplayersclient',function(data){  
            if(data.roominfo.where === 'lobby'){
                setHideSignIn("none");
                setShowWaitingRoom("flex");
            }
            if(data.roominfo.where === 'roundstart'){
                setHideSignIn("none");
                setShowWaitingRoom("flex");
                setDesc(['ROUND STARTED! Select a story point and how many days you think it will take.',data.roominfo.desc]);
                setIsStarted('start');
                socket.emit("planningpoker_in_round", { playername:activePlayerClient, number:selfStoryPoint, room:roomName, desc:info});
            }
            if(data.roominfo.where === 'roundreveal'){
                setHideSignIn("none");
                setShowWaitingRoom("flex");
                setDesc(['ROUND REVEAL! Waiting on the host* to start a new round.',data.roominfo.desc]);
                setIsStarted('reveal');
            }
            setRoomName(data.room);  
            setPlayersList(data.playerslist);
            setActivePlayerClient(data.activeplayerclient);
        });

        socket.on('backtohome',function(data){
            setHideSignIn("block");
            setShowWaitingRoom("none")
        });  

        socket.on('checkforroom',function(data){
            setNewSocket(data.socket);
            let getroom = document.getElementById('room-name-reconnect').innerHTML; 
            let getplayer = document.getElementById('player-name-reconnect').innerHTML; 
            socket.emit("room_check", { room:getroom, playername:getplayer, socketid:data.socket });
        });  
        
        
    }, [socket]);
      
    return (
        <>
            <div className="extra-background-color" style={{backgroundColor: 'rgb(64, 73, 100)', width: '100%', height: '100vh', position: 'fixed'}}>
            </div>
            <div className="container ">
                <Navbar />
                
                <div className="content add-extra-on-mobile" style={{backgroundColor: '#fff', justifyContent: 'center'}}>
                    <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <div className="main-header" style={{padding:'10px 0 0px 0px'}}>Planning Poker</div>
                        <div className="main-body main-space-even" style={{padding: '0px 10px 10px 10px', textAlign: 'center', maxWidth:'1625px', width:'100%'}}>     
                            <div id='make-join-room' style={{display:hideSignIn}}>
                                <JoinRoom socket={socket} setRoomName={setRoomName} roomtype={roomType} maxplayers={99} />
                            </div>   

                            <div id='waiting-room' style={{display:showWaitingRoom, flexDirection:"column",alignItems:'center'}} >
                                
                                <div className="room-name flex-center" style={{alignItems:'center', marginTop:'15px'}}>Room: {roomName} <button className='input-button lobby-button' onClick={exitGame}>Leave</button></div>
                                
                                <div style={isStarted === 'start' ? {width:'100%', display:'flex', flexDirection:'column', alignItems:'center'} : {display:'none'}}>
                                    Story Points
                                    <div className="box-story-days">
                                        <PlanningPokerPointsDays type={'storypoints'} number={'1'} setSelfStoryPoint={setSelfStoryPoint} />
                                        <PlanningPokerPointsDays type={'storypoints'} number={'2'} setSelfStoryPoint={setSelfStoryPoint} />
                                        <PlanningPokerPointsDays type={'storypoints'} number={'3'} setSelfStoryPoint={setSelfStoryPoint} />
                                        <PlanningPokerPointsDays type={'storypoints'} number={'5'} setSelfStoryPoint={setSelfStoryPoint} />
                                        <PlanningPokerPointsDays type={'storypoints'} number={'8'} setSelfStoryPoint={setSelfStoryPoint} />
                                        <PlanningPokerPointsDays type={'storypoints'} number={'13'} setSelfStoryPoint={setSelfStoryPoint} />
                                        <PlanningPokerPointsDays type={'storypoints'} number={'21'} setSelfStoryPoint={setSelfStoryPoint} />
                                    </div>

                                    Days
                                    <div className="box-story-days">
                                        <PlanningPokerPointsDays type={'days'} number={'0.5'} setSelfDays={setSelfDays} />
                                        <PlanningPokerPointsDays type={'days'} number={'1'} setSelfDays={setSelfDays} />
                                        <PlanningPokerPointsDays type={'days'} number={'2'} setSelfDays={setSelfDays} />
                                        <PlanningPokerPointsDays type={'days'} number={'3'} setSelfDays={setSelfDays} />
                                        <PlanningPokerPointsDays type={'days'} number={'4'} setSelfDays={setSelfDays} />
                                        <PlanningPokerPointsDays type={'days'} number={'5'} setSelfDays={setSelfDays} />
                                        <PlanningPokerPointsDays type={'days'} number={'10'} setSelfDays={setSelfDays} />
                                    </div>
                                </div>
                                

                                <div style={playersList[0].name === activePlayerClient ? {display:'flex', flexDirection:'column', alignItems:'center', width:'100%', maxWidth:'500px'} : {display:'none'}}>
                                    <input className='stand-input spymaster-field' style={isStarted != 'start' ? {display:'block'} : {display:'none'}} placeholder='Description...' onChange={(event) => { setInfo(event.target.value); }} />
                                    <button className='input-button' style={isStarted != 'start' ? {display:'block'} : {display:'none'}} onClick={()=>startRound()}>Start</button>
                                    <button className='input-button' style={isStarted === 'start' ? {display:'block'} : {display:'none'}} onClick={()=>revealRound()}>Reveal</button>
                                </div>
                                <div className="description-box">
                                    {desc[0]}<br /><br /> {desc[1]}
                                </div>
                                <PlanningPokerPlayersList playersList={playersList} direction={'planningpoker'} activePlayerClient={activePlayerClient} selfDays={selfDays} selfStoryPoint={selfStoryPoint} isStarted={isStarted} />
                            </div>          
                            
                        </div>
                    </div>  
                </div>
                <div id='room-name-reconnect' style={{opacity:0}}>{roomName}</div>
                <div id='player-name-reconnect' style={{opacity:0}}>{activePlayerClient}</div>

            </div>
        </>
        
      );
}

export default PlanningPoker;


