import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import './css/Spyfall.css';
import io from "socket.io-client";
import JoinRoom from "./components/JoinRoom";
import PlayersList from "./components/PlayersList";
import SpyfallLocationItem from "./components/spyfall.js/SpyfallLocationItem";
import { useIsMount } from './components/useIsMount';
import { timers } from "jquery";
// const socket = io.connect("http://localhost:3001");
const socket = io.connect("https://robertcalamari-server.onrender.com");

const API_URL = '../../js/spyfall.json';

function SpyfallClone(props) {

    const [hideSignIn, setHideSignIn] = useState("block");
    const [showGameRoom, setShowGameRoom] = useState("none");
    const [allLocations, setAllLocations] = useState([]);
    const [randomLocations, setRandomLocations] = useState([]);
    const [showWaitingRoom, setShowWaitingRoom] = useState("none");
    const [playersList, setPlayersList] = useState([{id:0, name:'nameid', loggedin:true, roomname:'rname', host:false, gameinfo: {}}]);
    const [roomName, setRoomName] = useState('');
    const [startGameErrorMessage, setStartGameErrorMessage] = useState(':)');
    const [roomType, setRoomType] = useState('codename');
    const [gameIsOver, setGameIsOver] = useState('none');
    const [activePlayerClient, setActivePlayerClient] = useState('');
    const [newSocket, setNewSocket] = useState("");
    const [location, setLocation] = useState("");
    const [userRole, setUserRole] = useState("");
    const [showRole, setShowRole] = useState("");
    const [timerState, setTimerState] = useState(false);
    const [timer, setTimer] = useState('');
    
    const isMount = useIsMount(); 
    
    useEffect(() => {
          document.title = props.title; // eslint-disable-next-line
          fetchWord();
          window.addEventListener("touchstart", touchHandler, false);

    }, []); 
    
      const fetchWord = async () => {
        const response = await fetch(API_URL);
        const locations = await response.json();
        setAllLocations(locations);
      };

      function touchHandler(event){
        if(event.touches.length > 1){
            //the event is multi-touch
            //you can then prevent the behavior
            event.preventDefault()
        }
    }

    function startTimerForAll(){
        socket.emit("spyfall_timer", {room:roomName, startorstop:'start'});
        setTimerState(true);
    }

    function pauseTimerForAll(){
        socket.emit("spyfall_timer", {room:roomName, startorstop:'pause'});
        // setTimerState(false);
    }

    function startGame(){
        socket.emit("start_spyfall_game", { where:'gamestart', playername:activePlayerClient, room:roomName, allLocations});
    }

    function exitGame(){
        socket.emit("exit_spyfall_game", { where:'exitedgame', room:roomName});
    }

    const pad = num => String("0"+num).slice(-2);

    
    
    //   const updateTimer = () => {
    //     const currentTime = new Date().getTime();
    //     const totalTime = elapsedTime + (currentTime - startTime);
    //     const seconds = Math.floor(totalTime / 1000) % 60;
    //     const minutes = Math.floor(totalTime / 1000 / 60);
    
    //     console.log(`Elapsed Time: ${minutes} minutes ${seconds} seconds`);
    
    //     if (totalTime >= 8 * 60 * 1000) {
    //       stopTimer();
    //     }
    //   };

    useEffect(() => {
        socket.on('updateplayersclient',function(data){   
            console.log(data);
            if(data.roominfo.where === 'lobby'){
                setHideSignIn("none");
                setShowWaitingRoom("flex");
                setRoomName(data.room);  
                setPlayersList(data.playerslist);
                setActivePlayerClient(data.activeplayerclient);
            }else if(data.roominfo.where === 'gamestart'){
                setRoomName(data.room);  
                setPlayersList(data.playerslist);
                setActivePlayerClient(data.activeplayerclient);;  
                setHideSignIn("none");
                setShowWaitingRoom("none");
                setShowGameRoom("flex");
                setGameIsOver('none');
                setUserRole(data.playerinfo.role);
                setLocation(data.roominfo.pickedLocation);
                setRandomLocations(data.roominfo.randomLocations);
                const seconds = Math.floor(480) % 60;
                const minutes = Math.floor(480  / 60);
                setTimer(minutes +':'+pad(seconds));
            }            
        });
        
        socket.on('startgameerrormessage',function(data){
            setStartGameErrorMessage(data.msg);
            document.getElementsByClassName("start-game-error")[0].classList.add("show-opacity");
            setTimeout(() => {
                document.getElementsByClassName("start-game-error")[0].classList.remove("show-opacity");  
            }, 4000);
        });  

        socket.on('backtohome',function(data){
            setHideSignIn("block");
            setShowWaitingRoom("none");
            setShowGameRoom("none");
            setTimerState(false);
        });  

        socket.on('checkforroom',function(data){
            setNewSocket(data.socket);
            let getroom = document.getElementById('room-name-reconnect').innerHTML; 
            let getplayer = document.getElementById('player-name-reconnect').innerHTML; 
            socket.emit("room_check", { room:getroom, playername:getplayer, socketid:data.socket });
        });  

        socket.on('counter',function(data){
            if(data.timer <= 0){
                setTimer('0:00');
            }else{
                const seconds = Math.floor(data.timer) % 60;
                const minutes = Math.floor(data.timer  / 60);
                setTimer(minutes +':'+pad(seconds));
                setTimerState(true);
            }
        });  
        
        
    }, [socket]);
      
    return (
        <>
            <div className="extra-background-color" style={{backgroundColor: 'rgb(40, 40, 41)', width: '100%', height: '100vh', position: 'fixed'}}>
            </div>
            <div className="container ">
                <Navbar />
                
                <div className="content add-extra-on-mobile" style={{backgroundColor: '#fff', justifyContent: 'center'}}>
                    <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <div className="main-header" style={{padding:'10px 0 0px 0px'}}>Spyfall Clone</div>
                        <div className="main-body main-space-even" style={{padding: '0px 10px 10px 10px', textAlign: 'center', maxWidth:'1625px', width:'100%'}}>     
                            <div id='make-join-room' style={{display:hideSignIn}}>
                                <JoinRoom socket={socket} setRoomName={setRoomName} roomtype={roomType} maxplayers={12} />
                            </div>   

                            <div id='waiting-room' style={{display:showWaitingRoom, flexDirection:"column",alignItems:'center'}} >
                                
                                <div className="room-name flex-center" style={{alignItems:'center'}}>Room: {roomName} <button className='input-button lobby-button' onClick={exitGame}>Lobby</button></div>
                                <PlayersList playersList={playersList} direction={'horizontal'} activePlayerClient={activePlayerClient} />

                                <div className='start-game-error'>{startGameErrorMessage}</div>
                                <a className='rules-button' href="https://www.spyfall.app/gamerules" target='_blank'>Rules</a>
                                <button className='input-button start-game'style={playersList[0].name === activePlayerClient ? {display:'block'} : {display:'none'}} onClick={startGame}>Start Game</button>
                                <div className="gameboard">

                                </div>
                            </div>

                            <div id='game-room' style={{display:showGameRoom, flexDirection:"column",alignItems:'center'}}>
                                <div className="room-name flex-center" style={{alignItems:'center'}}>Room: {roomName} <button className='input-button lobby-button' onClick={exitGame}>Lobby</button></div>
                                <button className='input-button spyfall-button' onClick={()=>setShowRole((prev) => !prev)}>Show Role</button>
                                <div className="location-role-box flex-center" style={showRole === true ? {display:"block"} : {display:"none"}}>
                                    <div style={userRole === 'Spy' ? {display:'none'} : {display:'block'}}>Location: {location}</div>
                                    <div style={userRole === 'Spy' ? {display:'block'} : {display:'none'}}>Try to act like you belong!</div>
                                    <br />
                                    <div>Role: {userRole}</div>
                                </div>
                                <button className='input-button spyfall-button' style={timerState === false ? {display:'block'} : {display:'none'}} onClick={startTimerForAll}>Start Timer</button>
                                <button className='input-button spyfall-button' style={timerState === true ? timer == '0:00' ? {display:'block', color:'red'} : {display:'block'} : {display:'none'}} >{timer}</button>
                                <h1>Locations:</h1>
                                <div className="location-list">
                                    {randomLocations.map((item, key ) => <SpyfallLocationItem key={key} item={item} newkey={key} />)}
                                </div>
                            </div>                    
                            
                        </div>
                    </div>  
                </div>
                <div id='room-name-reconnect' style={{opacity:0}}>{roomName}</div>
                <div id='player-name-reconnect' style={{opacity:0}}>{activePlayerClient}</div>

            </div>
        </>
        
      );
}

export default SpyfallClone;


