import React, { useEffect, useState } from "react";
// import { setCookie } from "./components/GetSetCookie";

function JoinRoom({roomtype, socket, maxplayers}) {

    const [room, setRoom] = useState("");
    const [playerName, setPlayerName] = useState("");
    const [messageReceived, setMessageReceived] = useState("");
    let isMobile = false;

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
        isMobile = true;
    }else{
      // false for not mobile device
        isMobile = false;
    }

    const makeRoom = () => {
        console.log('Mobile: ', isMobile);
        socket.emit("make_room", { playername:playerName, room, roomtype, maxplayers: maxplayers, ismobile:isMobile });
    };

    const joinRoom = () => {
        console.log('Mobile: ', isMobile);
        socket.emit("make_player", { playername:playerName, room, roomtype, ismobile:isMobile });
    };

    socket.on('signInResponse',function(data){
        if(data.success){              
            // document.cookie = "user="+data.playername; 
            // document.cookie = "room="+data.room; 
            // socket.emit("update_lobby", {room:data.room, where:'lobby'});
            // console.log('you are connected to room: ' + data.room);
        } 
        else{
            setMessageReceived(data.msg);
            document.getElementsByClassName("join-game-error")[0].classList.add("show-opacity");
            setTimeout(() => {
            document.getElementsByClassName("join-game-error")[0].classList.remove("show-opacity");  
          }, "4000");
        }
    });
      
    return (
        <div className="join-room-container">
            <div className="join-prompt">Please enter a room name and the name you would like to be associated with. You can either create a room to start a new game, or join a room if someone you know already created a game.</div>
            <input className='stand-input' maxLength="15" placeholder='Room Name...' onChange={(event) => { setRoom(event.target.value); }} />
            <input className='stand-input' maxLength="15" placeholder='Player Name...' onChange={(event) => { setPlayerName(event.target.value); }} />
            <div>
                <button className='input-button' onClick={makeRoom}> Make Room</button>
                <button className='input-button' onClick={joinRoom}> Join Room</button>
            </div>
            <div className='join-game-error'> {messageReceived}</div>
        </div>
      );
}

export default JoinRoom;


