import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import './css/TrustNoone.css';
import io from "socket.io-client";
import JoinRoom from "./components/JoinRoom";
import PlayersList from "./components/PlayersList";

// Connect to the server
const socket = io.connect("https://robertcalamari-server.onrender.com");

function TrustNoone(props) {
    const [totalMoney, setTotalMoney] = useState(0);
    const [inventory, setInventory] = useState([]);
    const [showInventory, setShowInventory] = useState(false);
    const [showPlayerList, setShowPlayerList] = useState(false);
    const [playersList, setPlayersList] = useState([]);
    const [roomName, setRoomName] = useState('');
    const [activePlayerClient, setActivePlayerClient] = useState('');
    const [hideSignIn, setHideSignIn] = useState("block");
    const [showGameRoom, setShowGameRoom] = useState("none");
    const [showWaitingRoom, setShowWaitingRoom] = useState("none");
    const [startGameErrorMessage, setStartGameErrorMessage] = useState(':)');
    const [newSocket, setNewSocket] = useState("");
    const [userRole, setUserRole] = useState("");
    const [location, setLocation] = useState("");
    const [showRole, setShowRole] = useState("");
    const [timerState, setTimerState] = useState(false);
    const [timer, setTimer] = useState('');

    useEffect(() => {
        socket.on('update_total_money', (data) => {
            setTotalMoney(data.totalMoney);
        });

        socket.on('update_inventory', (data) => {
            setInventory(data.itemsHolding);
        });

        socket.on('updateplayersclient', function (data) {
            setPlayersList(data.playerslist);
            setRoomName(data.room);
            setActivePlayerClient(data.activeplayerclient);

            if (data.roominfo.where === 'lobby') {
                setHideSignIn("none");
                setShowWaitingRoom("flex");
            } else if (data.roominfo.where === 'gamestart') {
                setHideSignIn("none");
                setShowWaitingRoom("none");
                setShowGameRoom("flex");
                setUserRole(data.playerinfo.role);
                setLocation(data.roominfo.pickedLocation);
                setTimerState(true);
            }
        });

        socket.on('startgameerrormessage', function (data) {
            setStartGameErrorMessage(data.msg);
            document.getElementsByClassName("start-game-error")[0].classList.add("show-opacity");
            setTimeout(() => {
                document.getElementsByClassName("start-game-error")[0].classList.remove("show-opacity");
            }, 4000);
        });

        socket.on('backtohome', function () {
            setHideSignIn("block");
            setShowWaitingRoom("none");
            setShowGameRoom("none");
            setTimerState(false);
        });

        socket.on('checkforroom', function (data) {
            setNewSocket(data.socket);
            let getroom = document.getElementById('room-name-reconnect').innerHTML;
            let getplayer = document.getElementById('player-name-reconnect').innerHTML;
            socket.emit("room_check", { room: getroom, playername: getplayer, socketid: data.socket });
        });

        socket.on('counter', function (data) {
            if (data.timer <= 0) {
                setTimer('0:00');
            } else {
                const seconds = Math.floor(data.timer) % 60;
                const minutes = Math.floor(data.timer / 60);
                setTimer(minutes + ':' + pad(seconds));
                setTimerState(true);
            }
        });

        return () => {
            socket.off('update_total_money');
            socket.off('update_inventory');
            socket.off('updateplayersclient');
            socket.off('startgameerrormessage');
            socket.off('backtohome');
            socket.off('checkforroom');
            socket.off('counter');
        };
    }, []);

    const handleAddMoney = () => {
        socket.emit('add_money', { room: roomName });
    };

    const toggleInventory = () => {
        setShowInventory(!showInventory);
        socket.emit('get_inventory');
    };

    const togglePlayerList = () => {
        setShowPlayerList(!showPlayerList);
    };

    const startGame = () => {
        socket.emit("start_spyfall_game", { where: 'gamestart', playername: activePlayerClient, room: roomName });
    };

    const exitGame = () => {
        socket.emit("exit_spyfall_game", { where: 'exitedgame', room: roomName });
    };

    const pad = (num) => String("0" + num).slice(-2);

    return (
        <>
            <div className="extra-background-color" style={{ backgroundColor: 'rgb(40, 40, 41)', width: '100%', height: '100vh', position: 'fixed' }}></div>
            <div className="container">
                <Navbar />
                <div className="game-content">
                    <div className="total-money-display">
                        Total Money: ${totalMoney}
                        <button onClick={handleAddMoney}>Add $1</button>
                    </div>
                    <div className="top-left">
                        <button onClick={togglePlayerList}>Players</button>
                        {showPlayerList && (
                            <div className="player-list-popup">
                                <ul>
                                    {playersList.map((player, index) => (
                                        <li key={index} style={{ color: player.isAlive ? 'black' : 'red' }}>
                                            {player.name} - ${player.overallMoney}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="top-right">
                        <button onClick={toggleInventory}>Inventory</button>
                        {showInventory && (
                            <div className="inventory-popup">
                                <ul>
                                    {inventory.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

                <div id='make-join-room' style={{ display: hideSignIn }}>
                    <JoinRoom socket={socket} setRoomName={setRoomName} roomtype={'TrustNoone'} maxplayers={12} />
                </div>

                <div id='waiting-room' style={{ display: showWaitingRoom, flexDirection: "column", alignItems: 'center' }}>
                    <div className="room-name flex-center" style={{ alignItems: 'center' }}>
                        Room: {roomName}
                        <button className='input-button lobby-button' onClick={exitGame}>Lobby</button>
                    </div>
                    <PlayersList playersList={playersList} direction={'horizontal'} activePlayerClient={activePlayerClient} />
                    <div className='start-game-error'>{startGameErrorMessage}</div>
                    <button className='input-button start-game' style={playersList[0].name === activePlayerClient ? { display: 'block' } : { display: 'none' }} onClick={startGame}>Start Game</button>
                </div>

                <div id='game-room' style={{ display: showGameRoom, flexDirection: "column", alignItems: 'center' }}>
                    <div className="room-name flex-center" style={{ alignItems: 'center' }}>
                        Room: {roomName}
                        <button className='input-button lobby-button' onClick={exitGame}>Lobby</button>
                    </div>
                    <button className='input-button spyfall-button' style={timerState === false ? { display: 'block' } : { display: 'none' }} onClick={() => setTimerState(true)}>Start Timer</button>
                    <button className='input-button spyfall-button' style={timerState === true ? timer === '0:00' ? { display: 'block', color: 'red' } : { display: 'block' } : { display: 'none' }}>{timer}</button>
                </div>

                <div id='room-name-reconnect' style={{ opacity: 0 }}>{roomName}</div>
                <div id='player-name-reconnect' style={{ opacity: 0 }}>{activePlayerClient}</div>
            </div>
        </>
    );
}

export default TrustNoone;
