import React, { useState } from "react";

function SpyfallLocationItem({item, newkey}) {

    const [showStrike, setShowStrike] = useState(false);

    return (
        <div className="locations-list-item" style={showStrike === true ? {textDecoration:'line-through', color:'#8a8a8a'} : {}} key={newkey} onClick={()=>setShowStrike((prev) => !prev)}> {item.location} </div>
      );
}

export default SpyfallLocationItem;