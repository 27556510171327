import React, { useEffect, useState } from "react";

function PlanningPokerPlayersList({playersList, direction, activePlayerClient, selfDays, selfStoryPoint, isStarted}) {
      
     let playerListLobbyD = [];
     let playerDirection = 'inline-block';
     let playerAlign = 'left';
     let setBoxed = false;
     let setActiveColor = 'green';
     let storypoints = '-';
     let days = '-';
     let playername = '';

     
    if(direction === 'planningpoker'){
        setBoxed = true;
        setActiveColor = '#3ef7c2';
    }

    for(var i in playersList){
        if(i == 0){
            playername = playersList[i].name + '*';
        }else{
            playername = playersList[i].name
        }
        if(activePlayerClient === playersList[i].name){
            if(playersList[i].gameinfo.storypoints === '' || playersList[i].gameinfo.storypoints === undefined || playersList[i].gameinfo.storypoints === '-'){
                storypoints = '-';
            }else{
                    storypoints = playersList[i].gameinfo.storypoints;
            }
            if(playersList[i].gameinfo.days === '' || playersList[i].gameinfo.days === undefined || playersList[i].gameinfo.days === '-'){
                days = '-';
            }else{
                    days = playersList[i].gameinfo.days;
            }
            
            playerListLobbyD.push([playername, setActiveColor, storypoints , days]);
        }else{
            
            if(playersList[i].gameinfo.storypoints === '' || playersList[i].gameinfo.storypoints === undefined || playersList[i].gameinfo.storypoints === '-'){
                storypoints = '-';
            }else{
                if(isStarted === 'start'){
                    storypoints = <svg xmlns="http://www.w3.org/2000/svg" width="1vw" height="1vw" viewBox="0 0 24 24" fill="none" stroke="rgb(62, 247, 194)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>;
                }else{
                    storypoints = playersList[i].gameinfo.storypoints;
                }
            }
            if(playersList[i].gameinfo.days === '' || playersList[i].gameinfo.days === undefined || playersList[i].gameinfo.days === '-'){
                days = '-';
            }else{
                if(isStarted === 'start'){
                    days = <svg xmlns="http://www.w3.org/2000/svg" width="1vw" height="1vw" viewBox="0 0 24 24" fill="none" stroke="rgb(62, 247, 194)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>;
                }else{
                    days = playersList[i].gameinfo.days;
                }
            }
            
            playerListLobbyD.push([playername, '#365a79', storypoints , days]);
        }
    }  

    

    return (
        <>
        <div className="box-players" style={setBoxed === false ? {display:'none'} : {width:'100%'}}>
            {playerListLobbyD.map(item => 
                <div className="playerlist-player-boxed" key={item[0]} style={{borderColor:item[1], display:'flex', flexDirection:'column', justifyContent:'space-around'}}>  
                    <div style={{color:'rgb(172, 193, 255)'}}>
                        {item[0]}
                    </div>
                    <div style={{display:'flex', width:'100%', justifyContent:'space-around'}}>
                        <div>
                            {item[2]}
                            <div style={{color:'rgb(172, 193, 255)'}}>Story Point</div>
                        </div>
                        <div>
                            {item[3]}
                            <div style={{color:'rgb(172, 193, 255)'}}>Days</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
        </>
        
      );
}

export default PlanningPokerPlayersList;


