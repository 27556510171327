import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import './css/Codename.css';
import io from "socket.io-client";
import JoinRoom from "./components/JoinRoom";
import PlayersList from "./components/PlayersList";
import { getJsonFromUrl } from "./components/checkURL";
import { getCookie } from "./components/GetSetCookie";
import { useIsMount } from './components/useIsMount';
import CodenameBlueSide from "./components/codenames/CodenameBlueSide";
import CodenameRedSide from "./components/codenames/CodenameRedSide";
import CodenameExtraLeft from "./components/codenames/CodenameExtraLeft";
import CodenameExtraRight from "./components/codenames/CodenameExtraRight";
import CodenameGameBoard from "./components/codenames/CodenameGameBoard";
// const socket = io.connect("http://localhost:3001");
const socket = io.connect("https://robertcalamari-server.onrender.com");

const API_URL = '../../js/codenamewordlist.json';

function CodenamesClone(props) {

    const [hideSignIn, setHideSignIn] = useState("block");
    const [showGameRoom, setShowGameRoom] = useState("none");
    const [allWords, setAllWords] = useState([]);
    const [selectedWords, setSelectedWords] = useState([]);
    const [redWords, setRedWords] = useState([]);
    const [blueWords, setBlueWords] = useState([]);
    const [scoreRed, setScoreRed] = useState(0);
    const [scoreBlue, setScoreBlue] = useState(0);
    const [currPlayAction, setCurrPlayAction] = useState(' ');
    const [currTeam, setCurrTeam] = useState('');
    const [cardClicked, setCardClicked] = useState('');
    const [grayWord, setGrayWord] = useState([]);
    const [spymasterRedList, setSpymasterRedList] = useState([[],[]]);
    const [spymasterBlueList, setSpymasterBlueList] = useState([[],[]]);
    const [showWaitingRoom, setShowWaitingRoom] = useState("none");
    const [playersList, setPlayersList] = useState([{id:0, name:'nameid', loggedin:true, roomname:'rname', host:false, gameinfo: {}}]);
    const [roomName, setRoomName] = useState('');
    const [startGameErrorMessage, setStartGameErrorMessage] = useState(':)');
    const [redTeam, setRedTeam] = useState([]);
    const [blueTeam, setBlueTeam] = useState([]);
    const [clickedCards, setClickedCards] = useState([[],[]]);
    const [roomType, setRoomType] = useState('codename');
    const [showWhileGivingClue, setShowWhileGivingClue] = useState(false);
    const [disableWhileGuessing, setDisableWhileGuessing] = useState('block');
    const [disableSkip, setDisableSkip] = useState(true);    
    const [gameIsOver, setGameIsOver] = useState('none');
    const [activePlayerClient, setActivePlayerClient] = useState('');
    const [code, setCode] = useState("");
    const [newSocket, setNewSocket] = useState("");
    
    const [guesses, setGuesses] = useState(0);
    const isMount = useIsMount(); 
    
    useEffect(() => {
          document.title = props.title; // eslint-disable-next-line
          fetchWord();
          window.addEventListener("touchstart", touchHandler, false);
    }, []); 
    
      const fetchWord = async () => {
        const response = await fetch(API_URL);
        const words = await response.json();
        setAllWords(words);
      };

      function touchHandler(event){
        if(event.touches.length > 1){
            //the event is multi-touch
            //you can then prevent the behavior
            event.preventDefault()
        }
    }

    function joinTeam(color){
        socket.emit("join_codename_team", { playername:activePlayerClient, room:roomName, color});
    }

    function isSpymaster(){
        if(spymasterRedList[0].name === activePlayerClient || spymasterBlueList[0].name === activePlayerClient){
            return true;
        }else{
            return false;
        }
    }

    function isCurrTeam(){
        for(let i in playersList){ 
            if(playersList[i].name == activePlayerClient){
                if(playersList[i].gameinfo.color == currTeam){
                    return true; 
                }else{
                    return false;
                }
            }
        }
    }

    function checkWhatCardItIs(card, dataredWords, datablueWords, datagrayWord){
        if(datablueWords.includes(card)){
            return 'blue';
        }else if(dataredWords.includes(card)){
            return 'red';
        }else if(datagrayWord.includes(card)){
            return 'gray';
        }else{
            return 'blank';
        }
    }

    //card clicked
    useEffect(() => {
        if (isMount) {
        } else {
            let newclicked = [];
            if(clickedCards == [[],[]]){
                newclicked.push(cardClicked);
            }else{
                newclicked = [...clickedCards];
                newclicked.push(cardClicked);
            }
            setClickedCards(newclicked); 

            let enemyteamcolor = currTeam == 'red' ? 'blue' : 'red';
            let clickedmsg = '';
            let cardcolor = checkWhatCardItIs(cardClicked, redWords, blueWords, grayWord);
        
            if(cardcolor == currTeam){
                
                let newblue = scoreBlue;
                let newred = scoreRed;
                if(currTeam == 'red'){
                    newred--;
                }else if(currTeam == 'blue'){
                    newblue--;
                }

                if(guesses-2 < 0){
                    clickedmsg = 'You found another ' + currTeam + ' team! (' + cardClicked + ') -  But that is all of your guesses! Now it is time for ' + enemyteamcolor + ' team to come up with a clue!';
                    socket.emit("send_clicked_card_next_turn", { where:'cardclickednextturn', room:roomName, guesses:guesses-1, cardClicked:cardClicked, clickedCards:newclicked, scoreBlue:newblue, scoreRed:newred, clickedMsg:clickedmsg, currTeam:enemyteamcolor});
                }else{
                    clickedmsg = 'Great job ' + currTeam + ' team! You found one (' + cardClicked + ')! Keep going! The clue is ' + code + ' and your guesses left: ' + (guesses-2) + ' + 1 extra!';
                    socket.emit("send_clicked_card_cont_turn", { where:'cardclickedcontturn', room:roomName, guesses:guesses-1, cardClicked:cardClicked, clickedCards:newclicked, scoreBlue:newblue, scoreRed:newred, clickedMsg:clickedmsg});
                }
                
                reduceScoreDown(currTeam, scoreRed, scoreBlue, roomName);
            }else if(cardcolor == 'gray'){
                gameOver(enemyteamcolor, roomName, 'gray');
            }else if(cardcolor == 'blank'){
                clickedmsg = 'A blank card has been clicked! (' + cardClicked + ') -  Now it is time for ' + enemyteamcolor + ' team to come up with a clue!';
                socket.emit("send_clicked_card_next_turn", { where:'cardclickednextturn', room:roomName, guesses:guesses-1, cardClicked:cardClicked, clickedCards:newclicked, scoreBlue, scoreRed, clickedMsg:clickedmsg, currTeam:enemyteamcolor});
            }
            else{
                let newblue = scoreBlue;
                let newred = scoreRed;
                if(enemyteamcolor == 'red'){
                    newred--;
                }else if(enemyteamcolor == 'blue'){
                    newblue--;
                }
                clickedmsg = 'Oh no ' + currTeam + ' team! You found the enemies card! (' + cardClicked + ') -  Now it is time for ' + enemyteamcolor + ' team to come up with a clue!';
                if(reduceScoreDown(enemyteamcolor, scoreRed, scoreBlue, roomName) == 'nogameover'){
                    socket.emit("send_clicked_card_next_turn", { where:'cardclickednextturn', room:roomName, guesses:guesses-1, cardClicked:cardClicked, clickedCards:newclicked, scoreBlue:newblue, scoreRed:newred, clickedMsg:clickedmsg, currTeam:enemyteamcolor});
                }
            }
        }
        
    }, [cardClicked]); 

    function sendClue(){
            setTimeout(() => {
                document.getElementsByClassName("spymaster-field")[0].value = '';
                document.getElementsByClassName("spymaster-field")[1].value = '';
                document.getElementsByClassName("spymaster-field")[2].value = '';
                document.getElementsByClassName("spymaster-field")[3].value = '';
            }, 1000);
            let clickedmsg = 'Team ' + currTeam + ' it is your turn to guess! The clue is ' + code + ' and your guesses: ' + (guesses) + ' + 1 extra!';
              
            socket.emit("send_codename_clue", { where:'sentclue', room:roomName, code, guesses:guesses, clickedMsg:clickedmsg, scoreBlue, scoreRed});
    }

    function reduceScoreDown(datacurrTeam, datascoreRed, datascoreBlue, room){

        if(datacurrTeam =='red'){
            if(datascoreRed-1 <= 0){
                gameOver('red', room);
            }
        }else{
            if(datascoreBlue-1 <= 0){
                gameOver('blue', room);
            }
        }
        return 'nogameover';
    }

    function skipTurn(){
        let enemyteamcolor = currTeam == 'red' ? 'blue' : 'red';
        let clickedmsg = 'The ' + currTeam + ' team has skipped the rest of their turn! Now it is your turn ' + enemyteamcolor + ' team to come up with a clue!';
        socket.emit("send_clicked_card_next_turn", { where:'cardclickednextturn', room:roomName, guesses:guesses-1, cardClicked:cardClicked, clickedCards:clickedCards, scoreBlue, scoreRed, clickedMsg:clickedmsg, currTeam:enemyteamcolor});
    }

    function startGame(){
        let clickedmsg = 'Waiting on the ' + currTeam + ' team to give their clue!';
        socket.emit("start_codename_game", { where:'gamestart', playername:activePlayerClient, room:roomName, allWords, redTeam, blueTeam, clickedMsg:clickedmsg});
    }

    function exitGame(){
        socket.emit("exit_codename_game", { where:'exitedgame', room:roomName});
    }

    function newGame(){
        let clickedmsg = 'Waiting on the ' + currTeam + ' team to give their clue!';
        socket.emit("new_codename_game", { where:'gamestart', playername:activePlayerClient, room:roomName, allWords, code, guesses:guesses, redTeam, blueTeam, currTeam, cardClicked, clickedCards, scoreRed, scoreBlue, redWords, blueWords, grayWord, selectedWords, clickedMsg:clickedmsg});
    }

    function gameOver(winners, room, reason){
        let clickedmsg = '';
        if(reason == 'gray'){
            clickedmsg = 'OOPS! That was the gray card! The ' + winners + ' team has won!';
        }else{
            clickedmsg = 'The ' + winners + ' team has won by getting all of their cards first!';
        }
        socket.emit("game_over_codename", { where:'gameover', playername:activePlayerClient, room:room, code, guesses:guesses, currTeam, cardClicked, clickedCards, scoreRed, scoreBlue, redWords, blueWords, grayWord, winners, selectedWords, reason, clickedMsg:clickedmsg});
    }

    function randomizePlayers(){
        socket.emit("randomize_codename_team", { room:roomName});
    }

    function getTeamColors(playerslist){
        let blueTeamTemp = [];
        let redTeamTemp = [];
        for(var i in playerslist){
            if(playerslist[i].gameinfo.color === 'red'){
                redTeamTemp.push(playerslist[i]);
            }else if(playerslist[i].gameinfo.color === 'blue'){
                blueTeamTemp.push(playerslist[i]);
            }
        } 
        setBlueTeam(blueTeamTemp);
        setRedTeam(redTeamTemp);
    }

    useEffect(() => {
        socket.on('updateplayersclient',function(data){  
            if(data.roominfo.where === 'lobby'){
                setHideSignIn("none");
                setShowWaitingRoom("flex");
                setRoomName(data.room);  
                setPlayersList(data.playerslist);
                setActivePlayerClient(data.activeplayerclient);
                getTeamColors(data.playerslist);
            }else if(data.roominfo.where === 'gamestart'){
                setRoomName(data.room);  
                setPlayersList(data.playerslist);
                setActivePlayerClient(data.activeplayerclient);
                getTeamColors(data.playerslist);
                setRoomName(data.room);  
                setHideSignIn("none");
                setShowWaitingRoom("none");
                setShowGameRoom("flex");
                setGameIsOver('none');
                setDisableSkip(true);
                setClickedCards([],[]); 
                setSelectedWords(data.roominfo.selectedWords);
                setRedWords(data.roominfo.redWords);
                setBlueWords(data.roominfo.blueWords);
                setGrayWord(data.roominfo.grayWord);
                setSpymasterRedList(data.roominfo.spymasterredlist);
                setSpymasterBlueList(data.roominfo.spymasterbluelist);
                setCurrTeam(data.roominfo.currTeam);
                setCurrPlayAction(data.roominfo.clickedMsg);
                setScoreRed(data.roominfo.redWords.length);
                setScoreBlue(data.roominfo.blueWords.length);
                setDisableWhileGuessing('block');
                setShowWhileGivingClue(true);
            }else if(data.roominfo.where === 'sentclue'){
                setRoomName(data.room);  
                setPlayersList(data.playerslist);
                setActivePlayerClient(data.activeplayerclient);
                getTeamColors(data.playerslist);
                setRoomName(data.room);  
                setHideSignIn("none");
                setShowWaitingRoom("none");
                setShowGameRoom("flex");
                setGameIsOver('none');
                setDisableSkip(true);
                setSelectedWords(data.roominfo.selectedWords);
                setRedWords(data.roominfo.redWords);
                setBlueWords(data.roominfo.blueWords);
                setGrayWord(data.roominfo.grayWord);
                setSpymasterRedList(data.roominfo.spymasterredlist);
                setSpymasterBlueList(data.roominfo.spymasterbluelist);
                setClickedCards(data.roominfo.clickedCards); 
                setCurrTeam(data.roominfo.currTeam);
                setScoreRed(data.roominfo.scoreRed);
                setScoreBlue(data.roominfo.scoreBlue);
                setCurrPlayAction(data.roominfo.clickedMsg);
                setGuesses(data.roominfo.guesses);
                setCode(data.roominfo.code);
                setDisableWhileGuessing('none');
                setShowWhileGivingClue(false);
            }else if(data.roominfo.where === 'cardclickednextturn'){
                setRoomName(data.room);  
                setPlayersList(data.playerslist);
                setActivePlayerClient(data.activeplayerclient);
                getTeamColors(data.playerslist);
                setRoomName(data.room);  
                setHideSignIn("none");
                setShowWaitingRoom("none");
                setShowGameRoom("flex");
                setGameIsOver('none');
                setDisableSkip(true);
                setSelectedWords(data.roominfo.selectedWords);
                setRedWords(data.roominfo.redWords);
                setBlueWords(data.roominfo.blueWords);
                setGrayWord(data.roominfo.grayWord);
                setSpymasterRedList(data.roominfo.spymasterredlist);
                setSpymasterBlueList(data.roominfo.spymasterbluelist);
                setCurrTeam(data.roominfo.currTeam);
                setGuesses(data.roominfo.guesses);
                setCode(data.roominfo.code);
                setScoreRed(data.roominfo.scoreRed);
                setScoreBlue(data.roominfo.scoreBlue);
                setClickedCards(data.roominfo.clickedCards); 
                setCurrPlayAction(data.roominfo.clickedMsg);
                setDisableWhileGuessing('block');
                setShowWhileGivingClue(true);
            }else if(data.roominfo.where === 'cardclickedcontturn'){
                setRoomName(data.room);  
                setPlayersList(data.playerslist);
                setActivePlayerClient(data.activeplayerclient);
                getTeamColors(data.playerslist);
                setRoomName(data.room);  
                setHideSignIn("none");
                setShowWaitingRoom("none");
                setShowGameRoom("flex");
                setGameIsOver('none');
                setDisableSkip(false);
                setSelectedWords(data.roominfo.selectedWords);
                setRedWords(data.roominfo.redWords);
                setBlueWords(data.roominfo.blueWords);
                setGrayWord(data.roominfo.grayWord);
                setSpymasterRedList(data.roominfo.spymasterredlist);
                setSpymasterBlueList(data.roominfo.spymasterbluelist);
                setCurrTeam(data.roominfo.currTeam);
                setGuesses(data.roominfo.guesses);
                setCode(data.roominfo.code);
                setDisableWhileGuessing('none');
                setShowWhileGivingClue(false);
                setScoreRed(data.roominfo.scoreRed);
                setScoreBlue(data.roominfo.scoreBlue);
                setClickedCards(data.roominfo.clickedCards); 
                setCurrPlayAction(data.roominfo.clickedMsg);
            }else if(data.roominfo.where === 'gameover'){
                setRoomName(data.room);  
                setPlayersList(data.playerslist);
                setActivePlayerClient(data.activeplayerclient);
                getTeamColors(data.playerslist);
                setRoomName(data.room);  
                setHideSignIn("none");
                setShowWaitingRoom("none");
                setShowGameRoom("flex");
                setDisableSkip(true);
                setSelectedWords(data.roominfo.selectedWords);
                setRedWords(data.roominfo.redWords);
                setBlueWords(data.roominfo.blueWords);
                setGrayWord(data.roominfo.grayWord);
                setSpymasterRedList(data.roominfo.spymasterredlist);
                setSpymasterBlueList(data.roominfo.spymasterbluelist);
                setCurrTeam(data.roominfo.currTeam);
                setGuesses(data.roominfo.guesses);
                setCode(data.roominfo.code);
                setDisableWhileGuessing('none');
                setShowWhileGivingClue(false);
                setScoreRed(data.roominfo.scoreRed);
                setScoreBlue(data.roominfo.scoreBlue);
                setClickedCards(data.roominfo.clickedCards); 
                setGameIsOver('flex');
                setCurrPlayAction(data.roominfo.clickedMsg);
            }                
        });
        
        socket.on('startgameerrormessage',function(data){
            setStartGameErrorMessage(data.msg);
            document.getElementsByClassName("start-game-error")[0].classList.add("show-opacity");
            setTimeout(() => {
                document.getElementsByClassName("start-game-error")[0].classList.remove("show-opacity");  
            }, 4000);
        });  

        socket.on('backtohome',function(data){
            setHideSignIn("block");
            setShowWaitingRoom("none");
            setShowGameRoom("none");
        });  

        socket.on('checkforroom',function(data){
            setNewSocket(data.socket);
            let getroom = document.getElementById('room-name-reconnect').innerHTML; 
            let getplayer = document.getElementById('player-name-reconnect').innerHTML; 
            socket.emit("room_check", { room:getroom, playername:getplayer, socketid:data.socket });
        });  
        
        
    }, [socket]);
      
    return (
        <>
            <div className="extra-background-color" style={{backgroundColor: '#080620', width: '100%', height: '100vh', position: 'fixed'}}>
            </div>
            <div className="container ">
                <Navbar />
                
                <div className="content add-extra-on-mobile" style={{backgroundColor: '#fff', justifyContent: 'center'}}>
                    <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <div className="main-header" style={{padding:'10px 0 0px 0px'}}>Codenames Clone</div>
                        <div className="main-body main-space-even" style={{padding: '0px 10px 10px 10px', textAlign: 'center', maxWidth:'1625px', width:'100%'}}>     
                            <div id='make-join-room' style={{display:hideSignIn}}>
                                <JoinRoom socket={socket} setRoomName={setRoomName} roomtype={roomType} maxplayers={12} />
                            </div>   

                            <div id='waiting-room' style={{display:showWaitingRoom, flexDirection:"column",alignItems:'center'}} >
                                
                                <div className="room-name flex-center" style={{alignItems:'center'}}>Room: {roomName} <button className='input-button lobby-button' onClick={exitGame}>Lobby</button></div>
                                <PlayersList playersList={playersList} direction={'horizontal'} activePlayerClient={activePlayerClient} />
                                <div className="flex-center">
                                    <div className="codename-waiting-color code-name-red-team">
                                        <div className="main-header" style={{padding:'10px 0 10px 0px', fontSize:'20px', color:'#932020'}}>Red Team</div>
                                        <button className='input-button hover-red' onClick={() => joinTeam('red')}>Join Team</button>
                                            {redTeam.map((item, index) => <div key={index} className="team-color" style={{color:'red'}}> {item.name} </div>)}
                                    </div>
                                    <div className="codename-waiting-color code-name-red-team">
                                        <div className="main-header" style={{padding:'10px 0 10px 0px', fontSize:'20px', color:'#44c'}}>Blue Team</div>
                                        <button className='input-button hover-blue' onClick={() => joinTeam('blue')}>Join Team</button>
                                            {blueTeam.map((item, index) => <div key={index} className="team-color" style={{color:'#7474ff'}}> {item.name} </div>)}
                                    </div>
                                </div>
                                
                                <div className='start-game-error'>{startGameErrorMessage}</div>
                                <a className='rules-button' href="https://www.ultraboardgames.com/codenames/game-rules.php" target='_blank'>Rules</a>
                                <button className='input-button'style={playersList[0].name === activePlayerClient ? {display:'block'} : {display:'none'}} onClick={randomizePlayers}>Randomize</button>
                                <button className='input-button start-game'style={playersList[0].name === activePlayerClient ? {display:'block'} : {display:'none'}} onClick={startGame}>Start Game</button>
                                <div className="gameboard">

                                </div>
                            </div>

                            <div id='game-room' style={{display:showGameRoom, flexDirection:"column",alignItems:'center'}}>
                                
                                <div className="room-name flex-center" style={{alignItems:'center'}}>Room: {roomName} <button className='input-button lobby-button' onClick={exitGame}>Lobby</button></div>
                                <div className="message-prompt">
                                    {currPlayAction}
                                </div>
                                <div className="score-prompt">
                                    <div className="score-prompt-red">{scoreRed}</div>
                                    <div className="score-prompt-turn" style={currTeam == 'red' ? {backgroundColor:'#932020'} : {backgroundColor:'#20207e'}}>{currTeam}'s Turn</div>
                                    <div className="score-prompt-blue">{scoreBlue}</div>
                                </div>
                                <div className="just-for-web" style={{display:showGameRoom, flexDirection:"column",alignItems:'center'}}>
                                    <div className="codename-gameboard flex-center">
                                        <div className="codename-red-side flex-center">
                                            <CodenameRedSide selectedWords={selectedWords} grayWord={grayWord}  redWords={redWords} blueWords={blueWords} setCardClicked={setCardClicked} clickedCards={clickedCards} gameIsOver={gameIsOver} spymasterRedList={spymasterRedList} spymasterBlueList={spymasterBlueList} activePlayerClient={activePlayerClient} playersList={playersList} currTeam={currTeam} showWhileGivingClue={showWhileGivingClue} scoreBlue={scoreBlue} scoreRed={scoreRed} />
                                            <CodenameExtraLeft selectedWords={selectedWords} grayWord={grayWord}  redWords={redWords} blueWords={blueWords} setCardClicked={setCardClicked} clickedCards={clickedCards} gameIsOver={gameIsOver} spymasterRedList={spymasterRedList} spymasterBlueList={spymasterBlueList} activePlayerClient={activePlayerClient} playersList={playersList} currTeam={currTeam} showWhileGivingClue={showWhileGivingClue} scoreBlue={scoreBlue} scoreRed={scoreRed} />
                                        </div>
                                        <CodenameGameBoard selectedWords={selectedWords} grayWord={grayWord}  redWords={redWords} blueWords={blueWords} setCardClicked={setCardClicked} clickedCards={clickedCards} gameIsOver={gameIsOver} spymasterRedList={spymasterRedList} spymasterBlueList={spymasterBlueList} activePlayerClient={activePlayerClient} playersList={playersList} currTeam={currTeam} showWhileGivingClue={showWhileGivingClue} scoreBlue={scoreBlue} scoreRed={scoreRed} />
                                        <div className="codename-blue-side">
                                            <CodenameBlueSide selectedWords={selectedWords} grayWord={grayWord}  redWords={redWords} blueWords={blueWords} setCardClicked={setCardClicked} clickedCards={clickedCards} gameIsOver={gameIsOver} spymasterRedList={spymasterRedList} spymasterBlueList={spymasterBlueList} activePlayerClient={activePlayerClient} playersList={playersList} currTeam={currTeam} showWhileGivingClue={showWhileGivingClue} scoreBlue={scoreBlue} scoreRed={scoreRed} />
                                            <CodenameExtraRight selectedWords={selectedWords} grayWord={grayWord}  redWords={redWords} blueWords={blueWords} setCardClicked={setCardClicked} clickedCards={clickedCards} gameIsOver={gameIsOver} spymasterRedList={spymasterRedList} spymasterBlueList={spymasterBlueList} activePlayerClient={activePlayerClient} playersList={playersList} currTeam={currTeam} showWhileGivingClue={showWhileGivingClue} scoreBlue={scoreBlue} scoreRed={scoreRed} disableWhileGuessing={disableWhileGuessing} skipTurn={skipTurn} sendClue={sendClue} setGuesses={setGuesses} setCode={setCode} exitGame={exitGame} newGame={newGame} disableSkip={disableSkip} />
                                        </div>
                                    </div>
                                </div>
                                <div className="just-for-mobile" style={{display:showGameRoom, flexDirection:"column",alignItems:'center', display:'none'}}>
                                    <div className="codename-gameboard flex-center-column" style={{width:'100%'}}>
                                        <CodenameGameBoard selectedWords={selectedWords} grayWord={grayWord}  redWords={redWords} blueWords={blueWords} setCardClicked={setCardClicked} clickedCards={clickedCards} gameIsOver={gameIsOver} spymasterRedList={spymasterRedList} spymasterBlueList={spymasterBlueList} activePlayerClient={activePlayerClient} playersList={playersList} currTeam={currTeam} showWhileGivingClue={showWhileGivingClue} scoreBlue={scoreBlue} scoreRed={scoreRed} />
                                        <div style={{width:'100%'}}>
                                            <CodenameExtraRight selectedWords={selectedWords} grayWord={grayWord}  redWords={redWords} blueWords={blueWords} setCardClicked={setCardClicked} clickedCards={clickedCards} gameIsOver={gameIsOver} spymasterRedList={spymasterRedList} spymasterBlueList={spymasterBlueList} activePlayerClient={activePlayerClient} playersList={playersList} currTeam={currTeam} showWhileGivingClue={showWhileGivingClue} scoreBlue={scoreBlue} scoreRed={scoreRed} disableWhileGuessing={disableWhileGuessing} skipTurn={skipTurn} sendClue={sendClue} setGuesses={setGuesses} setCode={setCode} exitGame={exitGame} newGame={newGame} disableSkip={disableSkip} />
                                        </div>
                                        <div className="flex-center center-evenly" style={{width:'100%', marginTop:'15px'}}>
                                            <div className="codename-red-side flex-center">
                                                <CodenameRedSide selectedWords={selectedWords} grayWord={grayWord}  redWords={redWords} blueWords={blueWords} setCardClicked={setCardClicked} clickedCards={clickedCards} gameIsOver={gameIsOver} spymasterRedList={spymasterRedList} spymasterBlueList={spymasterBlueList} activePlayerClient={activePlayerClient} playersList={playersList} currTeam={currTeam} showWhileGivingClue={showWhileGivingClue} scoreBlue={scoreBlue} scoreRed={scoreRed} />
                                            </div>
                                            <div className="codename-blue-side flex-center">
                                                <CodenameBlueSide selectedWords={selectedWords} grayWord={grayWord}  redWords={redWords} blueWords={blueWords} setCardClicked={setCardClicked} clickedCards={clickedCards} gameIsOver={gameIsOver} spymasterRedList={spymasterRedList} spymasterBlueList={spymasterBlueList} activePlayerClient={activePlayerClient} playersList={playersList} currTeam={currTeam} showWhileGivingClue={showWhileGivingClue} scoreBlue={scoreBlue} scoreRed={scoreRed} />
                                            </div>
                                        </div>
                                        <div className="flex-center-column" style={{width:'100%'}}>  
                                            <CodenameExtraLeft selectedWords={selectedWords} grayWord={grayWord}  redWords={redWords} blueWords={blueWords} setCardClicked={setCardClicked} clickedCards={clickedCards} gameIsOver={gameIsOver} spymasterRedList={spymasterRedList} spymasterBlueList={spymasterBlueList} activePlayerClient={activePlayerClient} playersList={playersList} currTeam={currTeam} showWhileGivingClue={showWhileGivingClue} scoreBlue={scoreBlue} scoreRed={scoreRed} />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>                    
                            
                        </div>
                    </div>  
                </div>
                <div id='room-name-reconnect' style={{opacity:0}}>{roomName}</div>
                <div id='player-name-reconnect' style={{opacity:0}}>{activePlayerClient}</div>

            </div>
        </>
        
      );
}

export default CodenamesClone;


